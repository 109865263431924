.HomePage {
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  box-sizing: border-box;
  margin: 0 -2em;
}

.HomePage * {
  box-sizing: border-box;
}

.Showcase-Section,
.News-Section {
  padding: 10px 0;
}

.Showcase-Section {
  margin-left: 1em;
  margin-right: 0.75em;
  overflow: hidden;
  width: 100%;
}

.wdk-Showcase-ContentRow {
  border-radius: 8px;
  // border: 1px solid #d5d5d5;
  box-shadow: 0 0 0 1px #e0e0e0;
  overflow: hidden;
}
