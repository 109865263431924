.support-form-base {
  .support-form-contents {
    .support-form-body {
      h4.access-request-form-header {
        text-align: left;
      }
      .disabled-access-request-textarea {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .access-request-denial-ul {
        list-style: none;
        background-color: #ededed;
        width: fit-content;
        padding-left: 0.5em;
        margin-left: 2em;
      }
      .access-request-denial-ul > li {
        padding: 0.3em;
      }
    }
  }
}
