$red: #dd314e;

.StudySearchIconLinks {
  display: flex;

  &Item {
    margin: 0 0.5em;

    a,
    .link,
    .wdk-Link {
      color: black;
      transition: 250ms color;
      text-decoration: none;

      &:hover {
        color: $red;
      }
    }
  }
}

div.wdk-Answer .StudySearchIconLinks {
  font-size: 2em;
}
