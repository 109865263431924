$textColor: rgb(56, 76, 75);
$myLinkFooterColor: rgb(15, 89, 112);
$myStripeBgColor: #e8e8e8;

.wdk-Showcase {
  * {
    box-sizing: border-box;
  }
  .StudyCard-DetailsLink {
    padding: 0 12px 5px;
    margin-top: -7px;
  }
  .StudyCard-Heading {
    font-size: 1.2rem;
    align-items: flex-start;
    flex: 0 0 auto;
    padding: 12px 12px 5px;
    font-weight: 800;
    display: flex;
    a,
    a:link,
    a:visited,
    a:active {
      color: $textColor;
    }

    h2 {
      flex: 1;
      font-size: 1.4rem;
      margin: 0;
      padding: 0;
    }
  }

  .StudyCard-Categories {
    flex: 0 0 auto;
    .CategoryIcon {
      margin-top: -4px;
    }
  }

  .StudyCard-Stripe {
    width: 100%;
    margin: 0;
    padding: 7px 12px;
    flex: 0 0 auto;
    background-color: $myStripeBgColor;
    font-size: 0.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .StudyCard-Body {
    flex: 1;
    padding: 12px;
    font-size: 0.8rem;
    ul {
      padding-left: 0;
      li {
        margin-bottom: 5px;
      }
    }
  }

  .StudyCard-Download,
  .StudyCard-MyAnalyses {
    flex: 0 0 auto;
    padding: 0.35em 1.5em;
    text-align: center;
    font-size: 1em;
  }

  .StudyCard-LinkOuts {
    display: flex;
    justify-content: space-between;
  }

  .StudyCard-PreFooter {
    flex: 0 0 auto;
    padding: 5px 5px 0;
    text-align: center;
    text-transform: uppercase;
    /*font-weight: 500;*/
    background-color: $myLinkFooterColor;
    color: white;
  }

  .StudyCard-Footer {
    flex: 0 0 auto;
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: stretch;
    background-color: $myLinkFooterColor;
    padding: 0;
    margin: 0;

    .emptybox {
      padding: 5px 12px;
    }

    .box {
      flex: 0;
      padding: 5px 12px;
      cursor: pointer;
      transition: all 0.4s;
      -webkit-transition: all 0.4s;
      text-align: center;

      a {
        color: white;
      }
    }
  }

  .StudyCard-SearchLink:hover {
    // handle case where the footer elements are children of the link element (eg, in eda)
    &,
    .StudyCard-PreFooter,
    .StudyCard-Footer {
      background-color: rgb(12, 71, 90);
    }
  }
}
