.DataRestrictionModal-Wrapper {
  pointer-events: all;
  background-color: rgba(0, 0, 0, 0.8);
}
.DataRestrictionModal {
  background-color: white;
  flex: 0 0 auto;
  max-width: 750px;
  border-radius: 10px;
  box-shadow: 4px 4px 22px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 20px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }
  hr {
    border: none;
    margin: 10px auto;
    padding: 0;
    display: block;
    border-bottom: 1px solid #dd314e;
  }
  p {
    font-size: 17px;
    line-height: 1.6em;
  }
  button.btn {
  }

  .DataRestrictionModal-Buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
  }
}
