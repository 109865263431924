.DisclaimerModal {
  background-color: white;
  padding: 20px;
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  max-width: 500px;
  h1,
  h2,
  h3 {
    padding: 0;
    margin: 0 0 10px;
  }
  button {
    margin-top: 10px;
  }

  h1.DisclaimerModal-Product {
    text-align: center;
    big {
      font-family: 'Exo 2';
      letter-spacing: -2px;
    }
    small {
      display: block;
      font-size: 0.5em;
      text-transform: uppercase;
      font-weight: 200;
    }
  }
}
