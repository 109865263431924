.wdk-Hero {
  magrin: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
  box-sizing: border-box;
  background-size: cover;

  .stack {
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
