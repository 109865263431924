.CategoryIcon {
  cursor: default;
  box-sizing: border-box;
  border-radius: 50%;
  display: inline-block;
  padding: 0;
  margin: 5px;

  height: 30px !important;
  width: 30px !important;
  font-size: 23px !important;
  line-height: 23px !important;
  padding-top: 4px !important;

  font-weight: 500;
  text-align: center;
  color: white;
}
