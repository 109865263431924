$red: #dd314e;

.HeaderNav-Wrapper {
  width: 100%;
}

.HeaderNav {
  width: 100%;
  padding: 5px 10px;
  justify-content: space-between;
  position: relative;
  z-index: 1000000;
  // flex-wrap: nowrap;
  flex-wrap: wrap;

  #VEuPathLogo {
    position: absolute;
    top: 0;
    right: 0;
    width: '160px';
    opacity: 0.75;
    width: 9%;
  }

  &-Primary {
    flex: 2;
    justify-content: center;
    position: relative;
    z-index: 20;
  }

  .HeaderNav-Secondary {
    flex: 0.3;
    position: relative;
    justify-content: space-between;
    flex-wrap: nowrap;
    z-index: 10;
  }

  &-Switch {
    flex: 1;
    min-width: 800px;
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 1300px) {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      min-width: 0;
    }
  }

  .UserMenu {
    padding-right: 10px;
  }

  &,
  .box,
  .row {
    align-items: center;
  }

  &-Branding {
    padding: 0 5px;
    min-width: 360px;
    flex: 0;
    flex-wrap: nowrap;
    align-self: flex-start;

    .HeaderNav-Logo {
      max-width: 70px;
      margin: 0 10px 0 0;
    }

    h1.HeaderNav-Title {
      margin: 0;
      font-size: 55px;
    }

    p {
      margin: 0;
      padding: 0;
      opacity: 0.5;
      font-size: 16px;
      font-weight: 200;
      line-height: 1.3em;
      letter-spacing: 0.2px;
      code {
        font-family: 'Inconsolata', 'Menlo', 'Monaco', 'Courier New', 'Courier';
        background-color: black;
        display: inline-block;
        margin: 0 2px;
        padding: 1px 3px;
      }
      small {
        opacity: 0.7;
      }
    }
  }
}

.HeaderNav-Social {
  flex: 1;
  width: auto;
  margin-left: 4em;
  flex-wrap: nowrap;
  flex-basis: auto;
  position: relative;
  z-index: 1;
  justify-content: right;
  align-items: center;
  a.HeaderNav-Social-Link {
    &,
    &:link,
    &:active,
    &:visited {
      color: #fff;
    }
    opacity: 0.7;
    transition: all 0.6s;
    font-size: 25px;
    padding: 0 8px;
    display: block;
    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }
}

.HeaderNav-Title {
  a,
  &:link,
  &:visited,
  &:hover {
    color: $red;
    text-decoration: none;
    transition: all 0.5s;
    &:hover {
      transform: scale(0.95);
    }
    mark {
      color: white;
      background-color: transparent;
    }
  }
}

.HeaderNav-Sticky {
  * {
    box-sizing: border-box;
  }

  width: 100%;
  min-width: 1200px;
  height: 40px;
  position: fixed;
  top: 0;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #fff;
  padding: 7.5px;
  background-color: rgb(35, 115, 120);
  background-size: cover;
  background-position: center 35%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  pointer-events: all;

  .UserMenu {
    height: 100%;
    flex-direction: row;
    align-items: center;

    .UserMenu-Icon {
      font-size: 25px;
      margin-right: 10px;
    }
  }

  .HeaderNav-Social {
    margin-left: 2em;
  }

  .HeaderNav-Title {
    margin: 10px;
    padding-top: 3px;
    font-size: 25px;
  }

  .HeaderNav-Sticky-Logo {
    max-height: 30px;
    margin: 5px 0 0 10px;
  }

  .SiteMenu {
    width: auto;
    font-size: 0.9em;

    .SiteMenuItem {
      &:last-of-type {
        margin-right: auto;
      }
    }
  }
}
