$white: #e0e0e0;

.SiteMenuItem {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 15px;
  position: relative;
  flex: 1 0 auto;
  opacity: 0.85;
  transition: all 0.5s;

  &:hover {
    background-color: #ffffff24;
  }

  .fa-caret-down {
    margin-left: 10px;
  }

  a,
  a:link,
  a:active,
  a:visited {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    opacity: 1;
  }
  .SiteMenuItem-Submenu {
    left: -0.25em;
    top: 100%;
    padding: 10px 5px;
    z-index: 10000;
    text-align: left;
    position: absolute;
    white-space: nowrap;
    transition: all 0.5s;
    display: flex;
    height: auto;
    align-items: flex-start;
    flex-flow: column nowrap;
    justify-content: flex-start;
    transition: all 0.5s;
    margin-top: -10px;
    max-height: calc(100vh - 10em);

    .SiteMenu-Item-Submenu-Inner {
      border-radius: 4px;
      overflow: auto;
      height: auto;
      display: flex;
      align-items: flex-start;
      flex-flow: column nowrap;
      justify-content: flex-start;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
      background-color: #ededed;
    }

    a,
    a:link,
    a:active,
    a:visited {
      color: #222;
      text-decoration: none;
    }

    &.SiteMenuItem-Submenu--hidden {
      pointer-events: none;
      .SiteMenu-Item-Submenu-Inner {
        opacity: 0;
        visibility: hidden;
      }
      .SiteMenuItem {
        max-height: 0px;
        opacity: 0;
        visibility: hidden;
      }
    }

    .SiteMenuItem {
      margin: 0;
      padding: 0;
      opacity: 1;
      width: 100%;
      display: block;
      // max-height: 200px;
      border-bottom: 1px solid #fff;
      font-weight: 400;
      font-size: 0.8em;
      background-color: #ededed;
      transition: all 0.5s;

      & > .SiteMenuItem-Link,
      & > .SiteMenuItem-Text {
        margin: 0;
        width: 100%;
        display: block;
        overflow: hidden;
        min-width: 250px;
        white-space: nowrap;
        color: black;
        opacity: 1;
        padding: 7px 10px;
        transition: all 0.5s;
        background-color: lighten($white, 7%);
      }
      .SiteMenuItem-Link:hover {
        opacity: 1;
        color: #288b99;
      }

      & > .SiteMenuItem-Text {
        margin: 0;
        width: 100%;
        font-weight: 300;
        display: block;
        margin-bottom: -5px;
        padding: 10px;
        background-color: lighten($white, 5%);
        color: #555;
        &:hover {
          background-color: lighten($white, 5%);
          color: #555;
        }
      }
    }
  }
  &:last-of-type {
    .SiteMenuItem-Submenu {
      left: auto;
      right: 0;
    }
  }
}
